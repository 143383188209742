module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NJ4PX5P","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"doughwebsite","defaultLang":"en-us","accessToken":"MC5YbnVZaHhBQUFCOEFjSk50.77-977-9Qu-_vRzvv71bV1s077-9LT1I77-9We-_vS7vv73vv73vv73vv70F77-977-977-977-9Ae-_ve-_ve-_ve-_vQ","path":"/preview","previews":true,"pages":[{"type":"Standard_page","match":"/:uid","path":"/page","component":"/var/www/dough/dough-gatsby-react/src/templates/StandardPage.js"},{"type":"Blog_list_page","match":"/:uid","path":"/blogList","component":"/var/www/dough/dough-gatsby-react/src/templates/BlogListPage.js"},{"type":"Blog_post","match":"/blog/:uid","path":"/blogPost","component":"/var/www/dough/dough-gatsby-react/src/templates/BlogPost.js"},{"type":"Learn_page","match":"/:uid","path":"/learnPage","component":"/var/www/dough/dough-gatsby-react/src/templates/LearnPage.js"},{"type":"Homepage","match":"/:uid","path":"/homepage","component":"/var/www/dough/dough-gatsby-react/src/templates/LandingPage.js"}]},
    },{
      plugin: require('../plugins/gatsby-source-doughapp-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dough","defaultLang":"en-us","accessToken":"","previewPath":"/appPreview","previews":true,"pages":[{"type":"Discovery_video","match":"/learn/:uid","filterTagsIn":["display_on_web"],"path":"/learnVideo","component":"/var/www/dough/dough-gatsby-react/src/templates/DiscoveryVideo.js"},{"type":"Discovery_article","match":"/learn/:uid","filterTagsIn":["display_on_web"],"path":"/learnArticle","component":"/var/www/dough/dough-gatsby-react/src/templates/DiscoveryArticle.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900","IBM Plex Sans:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dough","short_name":"dough","description":"dough is what investing should be: unlimited commission-free stock trading, zero account minimums, and an easy to use mobile app filled with smart ideas.","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
