/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import linkResolver from './src/utils/linkResolver';

registerLinkResolver(linkResolver);
